/* Base styles for the hero heading */
.hero-heading {
    font-size: 3rem;
    line-height: 1.2;
    
  }
  
  /* Font weight utility class */
  .fw-900 {
    font-weight: 900 !important;
  }
  
  /* Responsive font sizes with increased weight */
  @media (max-width: 1400px) {
    .hero-heading {
      font-size: 3.5rem;
    }
  }
  
  @media (max-width: 1200px) {
    .hero-heading {
      font-size: 3.2rem;
    }
  }
  
  @media (max-width: 992px) {
    .hero-heading {
      font-size: 2.8rem;
    }
  }
  
  @media (max-width: 768px) {
    .hero-heading {
      font-size: 2.3rem;
    }
  }
  
  @media (max-width: 576px) {
    .hero-heading {
      font-size: 2rem;
    }
  }
  
  .hero-heading {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }



  /* Hero.css */

/* Extra small devices (phones) */
@media screen and (max-width: 575.98px) {
  .vh-100 {
    height: 60vh !important;
   
  }
  
  .hero-heading {
    font-size: 2rem !important;
  }
  
  .fs-4 {
    font-size: 1rem !important;
  }
  
  .btn-lg {
    font-size: 1rem !important;
    padding: 0.5rem 1.5rem !important;
  }
  
  .p-4 {
    padding: 1rem !important;
  }
}

/* Small devices (tablets) */
@media screen and (min-width: 576px) and (max-width: 767.98px) {
  .vh-100 {
    height: 70vh !important;
 
  }
  
  .hero-heading {
    font-size: 2.5rem !important;
  }
  
  .fs-4 {
    font-size: 1.15rem !important;
  }
}

/* Medium devices (small laptops) */
@media screen and (min-width: 768px) and (max-width: 991.98px) {
  .vh-100 {
    height: 80vh !important;
   
  }
  
  .hero-heading {
    font-size: 3rem !important;
  }
}

/* Large devices (desktops) */
@media screen and (min-width: 992px) and (max-width: 1199.98px) {
  .vh-100 {
    height: 90vh !important;
   
  }
}

/* Extra large devices */
@media screen and (min-width: 1200px) {
  .vh-100 {
    height: 100vh !important;
  
  }
}

/* Ensure image always maintains aspect ratio */
.vh-100 {
  width: 100%;
  object-position: left center;
}

/* Maintain overlay consistency */
.bg-dark.bg-opacity-50 {
  backdrop-filter: blur(2px);
}